import React from 'react';
import styles from './SocialContacts.module.scss';
import { ReactSVG } from 'react-svg';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import iconPhone from '../../assets/icons/phone.svg';
import iconEmail from '../../assets/icons/email.svg';
import iconViber from '../../assets/icons/viber.svg';
import iconWhatsapp from '../../assets/icons/whatsapp.svg';
import cx from 'classnames';

type Props = {
  className?: string;
};

export const SocialContacts = ({ className }: Props) => {
  const intl = useIntl();

  return (
    <div className={cx(styles.socials, className)}>
      <a
        href={`tel:${translate(intl, 'GENERAL.PHONE')}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ReactSVG src={iconPhone} />
      </a>
      <a
        href={`mailto:info@go2spa.lt`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ReactSVG src={iconEmail} />
      </a>
      <a
        href={`viber://add?number=${translate(intl, 'GENERAL.PHONE').replace(
          '+',
          '',
        )}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ReactSVG src={iconViber} />
      </a>
      <a
        href={`https://wa.me/${translate(intl, 'GENERAL.PHONE')}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ReactSVG src={iconWhatsapp} />
      </a>
    </div>
  );
};

export default SocialContacts;
