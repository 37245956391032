import React from 'react';
import styles from './Footer.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { routes } from '../../config/Router/routes';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import iconFacebook from '../../assets/icons/facebook.svg';
import iconInstagram from '../../assets/icons/instagram.svg';
import iconPhone from '../../assets/icons/phone.svg';
import iconEmail from '../../assets/icons/email.svg';
import iconViber from '../../assets/icons/viber.svg';
import iconWhatsapp from '../../assets/icons/whatsapp.svg';
import { NavigationItem } from '../Layout/Layout';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import ababaLogo from '../../assets/ababa_tech_logo_white.png';

type Props = {
  navigationItems: NavigationItem[];
  selectedLocale: Locale;
};

const Footer = ({ navigationItems, selectedLocale }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.logoSectionContainer}>
            <div className={styles.logoSection}>
              <NavLink to={getLocaleUrl(routes.homepage, selectedLocale)}>
                <ReactSVG src={logo} />
              </NavLink>
            </div>
          </div>
          <div className={styles.footerItemContainer}>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.CONTACTS')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.TOUR_OPERATOR')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.ADDRESS')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.PHONE')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.MOBILE')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.EMAIL')}
              </div>
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.MENU')}
              </div>
              {navigationItems.map((item) => (
                <Link
                  key={item.label}
                  to={item.to}
                  className={styles.footerItem}
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.SOCIAL')}
              </div>
              <div className={styles.socials}>
                <a
                  href="https://www.facebook.com/go2spa.lt"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconFacebook} />
                </a>
                <a
                  href="https://www.instagram.com/go2spa.lt"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconInstagram} />
                </a>
                <a
                  href={`tel:${translate(intl, 'GENERAL.PHONE')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconPhone} />
                </a>
                <a
                  href={`mailto:info@go2spa.lt`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconEmail} />
                </a>
                <a
                  href={`viber://add?number=${translate(
                    intl,
                    'GENERAL.PHONE',
                  ).replace('+', '')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconViber} />
                </a>
                <a
                  href={`https://wa.me/${translate(intl, 'GENERAL.PHONE')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ReactSVG src={iconWhatsapp} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          {translate(intl, 'FOOTER.COPY_RIGHTS')}
          <a
            href="https://ababa.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ababaLogo}
              alt="ababa.tech"
              height={'18px'}
              width={'94px'}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(Footer);
